<template>
  <div>
    <v-card>
      <v-card-title class="blue white--text">
        <span>Concurso  {{ concourseNumber }}</span>
        <v-spacer></v-spacer>
        <v-menu
          bottom
          left
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>{{ icons.mdiFilter }}</v-icon>
            </v-btn>
          </template>

          <v-list
            :disabled="filterControl"
          >
            <v-list-item
              v-for="(concourse, i) in concourses"
              :key="i"
              link
            >
              <v-list-item-title
                @click="getData(concourse.number)"
              >
                {{ concourse.number }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-card-title>

      <v-data-table
        :headers="headers"
        :items="dataList"
        :loading="loading"
        loading-text="Carregando..."
        :items-per-page="30"
        disable-sort
      >
        <!-- action -->
        <template #[`item.actions`]="{ item }">
          <v-icon
            medium
            class="me-2"
            @click="openModal(item)"
          >
            {{ icons.mdiEyeOutline }}
          </v-icon>
        </template>

        <!-- when data is empty-->
        <template v-slot:no-data>
          <v-text>
            Nenhum registro encontrado
          </v-text>
        </template>
      </v-data-table>
      <v-dialog
        v-model="modalOptions.modal"
        width="900"
      >
        <lottery-modal
          :key="(quota, participants_number)"
          :participant="participants_number"
          :quota="quota"
        ></lottery-modal>
      </v-dialog>
    </v-card>
  </div>
</template>

<script>
import axiosIns from '@axios'
import { mdiEyeOutline, mdiFilter } from '@mdi/js'
import lotteryModal from './LotteryModal.vue'

export default {
  components: {
    lotteryModal,
  },
  data: () => ({
    participants_number: '',
    quota: '',
    icons: {
      mdiFilter,
      mdiEyeOutline,
    },

    /* data table */
    headers: [
      { text: 'NÚMERO DE PARTICIPANTES', value: 'participants_number' },
      { text: 'COTA', value: 'quota' },
      { text: 'RESULTADO', value: 'draw_result' },
      { text: 'OPÇÕES', value: 'actions', align: 'end' },
    ],
    dataList: [],
    concourses: [],
    loading: true,

    /* filter */
    dataFilter: [],
    concourseNumber: '',
    filterControl: false,

    /* modal */
    modalOptions: {
      modal: false,
    },
  }),

  created() {
    this.initialize()
  },

  methods: {
    async initialize() {
      await axiosIns
        .get('/api/v1/integration/lottery/index')
        .then(res => {
          this.dataFilter = res.data.data
          this.dataFilter.forEach(item => {
            this.concourses.push({ number: item.concourse })
          })
        })
        .catch(() => {
          this.concourses.push({ number: 'Não foi possível carregar os concursos' })
        })
      await axiosIns
        .get(`/api/v1/integration/lottery/contemplated_quotas/${this.concourses[0].number}`)
        .then(res => {
          this.dataList = res.data.data
          this.concourseNumber = this.concourses[0].number
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },

    async getData(val) {
      this.dataList = []
      this.filterControl = true
      this.loading = true
      this.concourseNumber = val
      await axiosIns
        .get(`/api/v1/integration/lottery/contemplated_quotas/${val}`)
        .then(res => {
          this.filterControl = false
          this.dataList = res.data.data
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },

    openModal(item) {
      this.modalOptions.modal = true
      this.participants_number = item.participants_number
      this.quota = item.quota
    },
  },
}
</script>
