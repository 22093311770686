<template>
  <div>
    <v-card>
      <v-card-title class="blue white--text">
        <span>Participantes: {{ quota }} {{ participant }}</span>
      </v-card-title>

      <v-data-table
        :headers="headers"
        :items="list"
        loading-text="Carregando..."
        :items-per-page="21"
        hide-default-footer
        disable-sort
        fixed-header
        :height="heightDinamic"
      >
        <!-- when data is empty-->
        <template v-slot:no-data>
          <v-text>
            Nenhum registro encontrado
          </v-text>
        </template>
        <template #[`item.phone`]="{item}">
          <v-icon
            medium
            @click="chatWhatsapp(item.phone)"
          >
            {{ icons.mdiWhatsapp }}
          </v-icon>
          {{ item.phone }}
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
import axiosIns from '@/plugins/axios'
import { mdiWhatsapp } from '@mdi/js'

export default {
  props: {
    participant: {
      type: String,
      required: true,
    },
    quota: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    /* data table */
    heightDinamic: 400,
    headers: [
      { text: 'NOME CLIENTE', value: 'client_name' },
      { text: 'COTA', value: 'quota_number' },
      { text: 'TELEFONE', value: 'phone' },
    ],
    list: [],
    icons: {
      mdiWhatsapp,
    },
  }),

  created() {
    this.getItems()
  },

  methods: {
    async getItems() {
      await axiosIns
        .post('/api/v1/integration/lottery/possible_winners/', {
          quota_number: Number(this.quota),
          participants_number: this.participant,
        })
        .then(res => {
          this.list = res.data.data
        })
    },

    chatWhatsapp(phone) {
      window.open(`https://wa.me/55${phone}`, '_blank')
    },
  },
}
</script>
