import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCard,[_c(VCardTitle,{staticClass:"blue white--text"},[_c('span',[_vm._v("Concurso "+_vm._s(_vm.concourseNumber))]),_c(VSpacer),_c(VMenu,{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v(_vm._s(_vm.icons.mdiFilter))])],1)]}}])},[_c(VList,{attrs:{"disabled":_vm.filterControl}},_vm._l((_vm.concourses),function(concourse,i){return _c(VListItem,{key:i,attrs:{"link":""}},[_c(VListItemTitle,{on:{"click":function($event){return _vm.getData(concourse.number)}}},[_vm._v(" "+_vm._s(concourse.number)+" ")])],1)}),1)],1)],1),_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.dataList,"loading":_vm.loading,"loading-text":"Carregando...","items-per-page":30,"disable-sort":""},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VIcon,{staticClass:"me-2",attrs:{"medium":""},on:{"click":function($event){return _vm.openModal(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiEyeOutline)+" ")])]}},{key:"no-data",fn:function(){return [_c('v-text',[_vm._v(" Nenhum registro encontrado ")])]},proxy:true}],null,true)}),_c(VDialog,{attrs:{"width":"900"},model:{value:(_vm.modalOptions.modal),callback:function ($$v) {_vm.$set(_vm.modalOptions, "modal", $$v)},expression:"modalOptions.modal"}},[_c('lottery-modal',{key:(_vm.quota, _vm.participants_number),attrs:{"participant":_vm.participants_number,"quota":_vm.quota}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }