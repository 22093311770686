import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCard,[_c(VCardTitle,{staticClass:"blue white--text"},[_c('span',[_vm._v("Participantes: "+_vm._s(_vm.quota)+" "+_vm._s(_vm.participant))])]),_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.list,"loading-text":"Carregando...","items-per-page":21,"hide-default-footer":"","disable-sort":"","fixed-header":"","height":_vm.heightDinamic},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-text',[_vm._v(" Nenhum registro encontrado ")])]},proxy:true},{key:"item.phone",fn:function(ref){
var item = ref.item;
return [_c(VIcon,{attrs:{"medium":""},on:{"click":function($event){return _vm.chatWhatsapp(item.phone)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiWhatsapp)+" ")]),_vm._v(" "+_vm._s(item.phone)+" ")]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }